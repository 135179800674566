/*-----------------------------------------------
|   Dashboard
-----------------------------------------------*/
.file-thumbnail {
  height: 2.25rem;
  width: 2.25rem;
}

.dot {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  // display: inline-block;
  margin-right: map_get($spacers, 2);
}

/*-----------------------------------------------
|             E-commerce
-----------------------------------------------*/
// .greetings-item {
//   background-color: var(--#{$prefix}white);

//   .dark & {
//     background-color: var(--#{$prefix}card-bg);
//   }
// }

/*-----------------------------------------------
|             Project Management
-----------------------------------------------*/
.recent-activity-body-height {
  height: 25rem;
}

.table-member-info {
  min-width: 43rem;
}

.members-activity {
  min-height: 22rem;
}

.table-running-project {
  min-width: 43rem;
}

.hover-actions-trigger.btn-reveal-trigger {
  .hover-actions {
    right: 3.5rem;
    [dir='rtl'] & {
      right: 3.5rem;
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                                  Analytics                                 */
/* -------------------------------------------------------------------------- */
.chart-tab {
  .nav-link {
    &:not(.active):hover {
      border-color: transparent !important;
    }
    &.active {
      border-bottom: 2px solid var(--#{$prefix}primary) !important;
      background-color: var(--#{$prefix}card-bg) !important;
      outline: none !important;
    }
  }
}
.ask-analytics {
  @include media-breakpoint-up(md) {
    max-height: 426px;
  }

  .ask-analytics-item {
    @include hover-focus {
      background: var(--#{$prefix}gray-100);
    }
  }
}
/* -------------------------------------------------------------------------- */
/*                                  CRM                                       */
/* -------------------------------------------------------------------------- */
.table-recent-leads {
  min-width: 38rem;
}

.tab-active-caret {
  .nav-link {
    color: var(--#{$prefix}gray-600) !important;
    font-size: map-get($font-sizes, '-1') !important;
    font-weight: $font-weight-medium !important;
    font-family: $font-family-sans-serif !important;
    &.active {
      color: var(--#{$prefix}primary) !important;
      border-bottom: 0 !important;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        bottom: -6px;
        left: 45%;
        transform: rotate(45deg);
        background: var(--#{$prefix}card-bg);
        border-radius: 0.125rem;
        border-width: 0 1px 1px 0;
        border-style: solid;
        border-color: $dropdown-border-color;
      }
    }
  }
}

// Used in crm deal forecast card
html[dir='rtl'] .rtl-row-reverse {
  flex-direction: row-reverse;
}
