// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
.navbar-vertical-header-odex {
  height: 3rem;
}

.filters-header-odex {
  width: 45px;
  height: 45px;

  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.filters-header-odex-md {
  width: 38px;
  height: 38px;

  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.filters-header-odex-sm {
  width: 30px;
  height: 30px;

  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.text-blue-1 {
  color: #1c4f93;
}

.text-green-1 {
  color: #1c754f;
}

.bg-grey-1 {
  background: #edf2f8;
}

.no-caret-dropdown-toggle::after {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.odex-mh-60px {
  min-height: 60px;
  max-height: 60px;
}

.rounded-pill-l {
  border-top-left-radius: var(--falcon-border-radius-pill) !important;
  border-bottom-left-radius: var(--falcon-border-radius-pill) !important;
}

.rounded-pill-r {
  border-top-right-radius: var(--falcon-border-radius-pill) !important;
  border-bottom-right-radius: var(--falcon-border-radius-pill) !important;
}

.odex-border-l-0px {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.odex-border-r-0px {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.odex-overlap-card {
  position: absolute;
  top: 0px;
  right: 0px;
}

.row [class*='col-'] {
  transition: all 1s ease;
}

.d-none {
  transform: d-none-animation 5s ease !important;
}

@keyframes d-none-animation {
  from {
    opacity: 1;
  }
  to {
    opacity: 2;
  }
}

.col-transition-animation {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 1s ease;
}

.d-none {
  transition: all 1s ease-in-out;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.animate-flicker {
  -webkit-animation: flickerAnimation 3s infinite;
  -moz-animation: flickerAnimation 3s infinite;
  -o-animation: flickerAnimation 3s infinite;
  animation: flickerAnimation 3s infinite;
}

.crossfade-logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top {
  animation-name: fade;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-direction: alternate;
  width: 100px;
  height: 100%;
  background-color: white;
  border-radius: 4px;
  position: absolute;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.odex-w-400px {
  width: 400px;
}

.odex-bg-aliceblue {
  background-color: aliceblue !important;
}

.z-inxe-agent {
  z-index: 1;
}
.bg-card {
  background-position: left;
}
.odex-bg-aliceblue {
  background-color: transparent;
}

.bg-card-blDetails {
  //  background-position: right;
  background-size: cover !important;
  background-position: center !important;
}

.justify-content-start {
  z-index: 10;
}

.odex-bldetails-img-r {
  background-image: url('../img/illustrations/cornerRight-1.png');
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.odex-bldetails-img {
  background-image: url('../img/illustrations/corner-1.png');
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  // z-index: 1;
}

.odex-text-grey-light {
  color: #c3c9d1;
}

.btn {
  z-index: 10;
}
.page {
  z-index: 10;
}
.zindex10 {
  z-index: 10;
}

.zindex5 {
  z-index: 5;
}

.odex-position-relative {
  position: relative;
}

.odex-position-absolute {
  position: absolute;
}

.odex-registration-address {
  bottom: -100%;
  height: 100px;
  z-index: 11 !important;
  overflow-y: auto;
}

.table > thead {
  vertical-align: bottom;
  border-top: solid 1px #ffe1da;
  border-bottom: solid 1px #ffe1da;
  background: rgb(255, 243, 242);
  /*background: linear-gradient(
    90deg,
    rgb(255 225 218) 10%,
    rgba(207, 161, 135, 0.412) 20%,
    rgba(179, 223, 241, 0.45) 100%,
    rgba(245, 130, 42, 0.31) 100% */
  background: linear-gradient(
    90deg,
    rgb(255 243 242) 10%,
    rgb(255 243 242) 20%,
    rgb(202 228 255) 100%,
    rgba(245, 130, 42, 0.31) 100%
  );
}

.odex-width-210px {
  width: 210px;
}

.odex-width-216px {
  width: 216px;
}

.odex-width-14rem {
  width: 16.5rem;
}
.innerDataTable table thead {
  background: #c3c9d1;
  border: none;
}
.innerDataTable table thead th {
  border-bottom: none;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
  text-align: center;
}

.react-pdf__Page__annotations {
  display: none;
}
.card {
  z-index: 10;
}

.resizer-root:hover {
  .resizer {
    display: inline-block;
    background: aqua;
    width: 5px;
    height: 20px;

    transform: translateX(50%);
    z-index: 1;

    touch-action: none;

    &.isResizing {
      background: lightblue;
    }
  }
}

.isResizing {
  background: darkorchid;
}

.mandatory {
  color: red;
}

.odex-root-component {
  height: 89vh !important;
}

.odex-data-table-root {
  height: 68vh !important;
  overflow-y: auto;
  display: block;
  overflow-x: hidden;
}

.odex-data-table-root-auto {
  height: auto;
  overflow-y: auto;
  display: block;
  overflow-x: hidden;
}

b,
strong {
  font-weight: 600;
}

.editFormCheck[type='checkbox'] {
  .form-check {
    margin: 0px;
  }
}

.odex-h-150px {
  height: 150px !important;
}

.form-select,
option {
  font-weight: 450;
}

textarea.form-control {
  font-size: 12px;
}
.infoIcon {
  color: #a3a6a9 !important;
}
.infoIcon:hover {
  color: #495461 !important;
}
th {
  border-bottom: 0px;
}
.odex-bg-blue {
  background-color: #cde5fe;
}

.odex-bg-grey {
  background-color: #e6ebf3;
}

.odex-overflowY-scroll {
  overflow-y: scroll;
}
.fa-chart-pie,
.fa-file-pen,
.fa-cart-shopping,
.fa-credit-card,
.fa-upload,
.fa-gear,
.fa-floppy-disk,
.fa-arrow-rotate-right,
.fa-xmark,
.fa-plus,
.fa-user-check,
.fa-tasks,
.fa-pen-to-square,
.fa-chevron-right,
.fa-magnifying-glass,
.fa-chevron-left,
.fa-circle-check,
.fa-circle-xmark,
.fa-square-check,
.fa-rectangle-xmark,
.fa-arrow-right,
.fa-arrow-up,
.fa-arrow-down,
.fa-user,
.fa-tools,
.fa-circle-left,
.fa-file-arrow-down,
.fa-youtube,
.fa-book,
.fa-list-ul {
  color: #f5822a;
}

button:has(.btn-falcon-dange) {
  background: red;
}

.textHover:hover:not(.disabled):not(:disabled),
.textHover:focus:not(.disabled):not(:disabled) {
  color: #2566be;
  background-color: transparent;
  border: none;
}
td .fa-circle-check {
  color: #0ea364cf !important;
}
.approve .fa-check {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  color: #f5822a;
  padding: 2px;
  position: relative;
  top: 2px;
}
.reject .fa-xmark {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  color: #f5822a;
  padding: 2px;
  position: relative;
  top: 1px;
}
.odex-text-grey-light {
  color: #c3c9d1 !important;
}
.react-datepicker__input-container input {
  font-size: 0.83rem !important;
}
.Toastify__toast--error .Toastify__toast-body {
  color: #fff;
}
.Toastify__toast--error {
  color: red;
}
table tr th div.justify-content-start {
  z-index: 1 !important;
}

// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.form-login {
  width: 400px;
  background: transparent;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  margin: 0px auto;
  clear: both;
}

.login-wrap .cardHeader {
  padding: 15px 15px !important;
  text-align: center;
  text-transform: uppercase;
  padding: 1px 0;
  border-radius: 10px 10px 0 0;
  background-color: #444;
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  margin: 0px 0px !important;
  font-family: 'Roboto', sans-serif;
  opacity: 0.74 !important;
}
.inputBorder {
  border: solid 1px #aba5a5;
}

.login-wrap {
  background: #fff;
  opacity: 0.9;
  height: 100%;
}
.bodyHeight {
  max-height: 200px;
  border: solid 1px #808081;
}

.bodyHeightReset {
  min-height: 270px;
  border: solid 1px #808081;
  border-radius: 0px 0px 10px 10px;
}

.bodyHeightForgot {
  border: solid 1px #808081;
  border-radius: 0px 0px 10px 10px;
}

.form-footer {
  background-color: #717070;
  padding: 6px 6px;
  border-radius: 0px 0px 10px 10px;
}

.form-footer .link {
  color: #fff;
  font-size: 12px !important;
}

.form-footer .link:hover {
  color: #ccc !important;
  text-decoration: none;
}
.fa-check,
.fa-lock,
.fa-file,
.fa-magnifying-glass-plus {
  font-size: 10px !important;
  color: #fff;
  border-radius: 50%;
  background-color: #505050;
  padding: 4px 4px;
  margin-right: 5px;
  width: 10px;
  height: 10px;
}
.logoWidth {
  width: 200px;
  margin: 0 auto;
}
.copyWriteText {
  position: absolute;
  bottom: 10px;
  text-align: center !important;
  left: 0;
  right: 0;
  font-size: 12px !important;
}
.btn-info {
  background-color: #1c9ed6 !important;
}

.iconBody .svg-inline--fa.fa-lg {
  vertical-align: -0.4em !important;
}

.active span .fa-cart-shopping {
  color: #2c7be5 !important;
}

.active span .fa-chart-pie {
  color: #2c7be5 !important;
}
.active span .fa-file-pen {
  color: #2c7be5 !important;
}
.active span .fa-user {
  color: #2c7be5 !important;
}

.active span .fa-book {
  color: #2c7be5 !important;
}

.active span .fa-credit-card {
  color: #2c7be5 !important;
}

.active span .fa-list-ul {
  color: #2c7be5 !important;
}

.odex-z-index--2 {
  position: relative;
  z-index: -2;
}
.textLowerCase {
  text-transform: capitalize !important;
}

.userAcAddBtn {
  position: relative;
  top: 26px;
  left: 139px;
}

//## BL and Invoice Dashboard css overwrite ## //

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem !important;
}

.navbar-top {
  min-height: 3.3125rem !important;
  height: 3rem !important;
}
.odex-mh-60px {
  min-height: 50px !important;
  max-height: 50px !important;
}
.content {
  padding-bottom: 0.1875rem !important;
}
.dropdown-container {
  height: 32px !important;
}

.resizer {
  height: 20px !important;
}

//## BL and Invoice Dashboard css overwrite end ## //

/*
.iconBody {
  font-size: 10px !important;
  color: #fff;
  border-radius: 50%;
  background-color: #505050;
  padding: 3px;
  margin: 0px 2px;
}

.icon {
  font-size: 10px !important;
  color: #fff;
  padding: 2px;
  width: 15px;
  height: 15px;
  margin: 0px 2px;
}
*/
.carousel-indicators {
  display: none !important;
}
.carousel {
  height: 100px !important;
}

.demmurageTable table {
  min-height: auto !important;
}

/*Reset Paassword */
fieldset {
  border: solid 1px #808081;
  border-radius: 5px;
  margin-top: 15px;
  padding: 10px;
  font-size: 12px;
  margin-bottom: 0px;
  padding-bottom: 10px;
}
legend {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
}
fieldset p {
  margin-bottom: 0;
}
.disablePayment .fa-credit-card {
  color: #ccc !important;
}

.containerDetails table tbody tr td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

table tbody tr td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.usdiscMes {
  font-size: 14px;
  color: #f5822a;
  font-weight: bold;
  margin-left: 100px;
  position: absolute;
  top: 5px;
  width: 49%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.customerProfile {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  padding: 0px;
  color: #edf2f9;
  font-size: 20px;
  text-transform: uppercase;
  background-image: linear-gradient(to right, #f5822a, #0595d2);
}

.linerProfileIcon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  padding: 0px;
  color: #edf2f9;
  font-size: 20px;
  background-color: #a3a6a9;
  text-transform: uppercase;
}
fieldset {
  border: solid 1px #d8e2ef;
}

.simplebar-track {
  bottom: -3px !important;
}

.navbar-vertical.navbar-expand-xl + .content {
  margin-left: 11.725rem;
}
.navbar-vertical.navbar-expand-xl .navbar-collapse {
  width: 11.525rem;
}
.navbar-vertical.navbar-expand-xl {
  width: 11.525rem;
}
/* .navbar-vertical.navbar-expand-xl .navbar-vertical-content {
  width: 11.525rem;
} */
.releaseDate .react-datepicker-wrapper {
  width: 100%;
}
hr {
  margin: 5px;
}

.dropdown > div {
  padding: 5px 0px 2px 2px;
}
.dropdown-divider {
  margin: 2px;
}
.modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--falcon-modal-margin) * 2);
}
.btn-close {
  position: absolute;
  right: 5px;
  top: 6px;
  font-size: 12px;
}

.textTransform {
  text-transform: lowercase;
}

.carousel .carousel-item {
  transition: transform 2s ease, opacity 0.5s ease-out;
}

.dropControl {
  height: 2rem;
  min-height: 2rem;
  padding: 0px;
  margin: 0px;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  display: flex;
  align-content: center;
  justify-content: center;
  border: #d8e2ef 1px solid;
  font-family: Poppins, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 0.83rem;
  font-weight: 400;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  background-clip: padding-box;
  border-radius: 0.25rem;
  color: #344050;
  line-height: 1.5;
  box-sizing: border-box;
}

.smoothDiv {
  transition: width 1s ease;
}

.smoothDivH {
  transition: height 1s ease;
}

.col-lg-0 {
  width: 0px;
}

.asyncDropField {
  transition: box-shadow 0.2s ease-in-out;
  height: 2rem;
  min-height: 2rem;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.disabledField {
  background-color: #eaeff5;
  color: #495057;
  font-size: 0px !important;
}

.focusField {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.25rem rgba(25, 118, 218, 0.25);
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #d7dde7c7;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b7bcc4d8;
}
.react-datepicker-popper {
  z-index: 99999999 !important;
}

@keyframes pulse {
  0% {
      opacity: 1;
  }
  50% {
      opacity: 0.3;
  }
  100% {
      opacity: 1;
  }
}
.loadingPulse {
  animation: pulse 1s infinite;
}

.infoTooltipIcon{
  position:absolute;
  z-index: 999;
  top:-8px;
  right:-8px;
}

.infoTooltipIcon:hover {
  color: #0595d2;
}

.payPalModal .modal-content{
  background: transparent;
    box-shadow: none;
    border: none;
}

.payPalModal .modal-lg{
  width:600px;
}
.demmurageTableCheckList table tbody tr td
  {
   text-wrap:wrap;
}

#tags {
   
  margin: 0px auto;
  overflow: hidden;
   
}

#tags .ReactTags__tags {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
 // padding: 5px 10px;
}

#tags .ReactTags__selected {
  display: flex;
}

#tags .ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

#tags .ReactTags__tagInput {
  border-radius: 2px;
  display: inline-block;
}

#tags .ReactTags__tagInput input.ReactTags__tagInputField, #tags .ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  min-width: 150px;
  padding: 0px 10px;
}

#tags .ReactTags__editInput {
  border-radius: 1px;
}

#tags .ReactTags__editTagInput {
  display: inline-flex;
}

#tags .ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background:hsl(0deg 0% 90.2%);
  color:  hsl(0, 0%, 20%);
  font-size: 12px;
  display: flex;
  padding: 5px;
  margin: 0 5px;
  border-radius: 2px;
}

#tags .ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

#tags .ReactTags__suggestions {
  position: absolute;
}

#tags .ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}

#tags .ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

#tags .ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

#tags .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

#tags .ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: hsl(0, 0%, 20%);
  margin-left: 5px;
}

#tags input {
  background-color: #f9f9f9;
  border: none;
  outline: none;
}

#tags input:before {
  border: none;
}