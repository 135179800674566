$primaryColor: grey;

//text color

$textSecondaryColor: #24416b;
@import './button.scss';
@import './home.scss';
@import './card.scss';
@import './text.scss';
@import './sideNav.scss';
@import './header.scss';

@import './theme.scss';
@import './user.scss';

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
a {
  text-decoration: none;
}

.odexLogo {
  width: 60%;
}

.image-container {
  height: 100%;
  width: 100%;
  float: left;
  position: relative;
}

.image-container img {
  width: 100%;
  height: 100%;
  // object-fit: cover;
}

.carousel-container {
  position: absolute;
  top: '50%';
  left: '50%';
}

.signup-box {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  float: left;
  height: 100%;
}

.carousel-inner {
  border-radius: 0rem !important;
}

.carousel {
  height: 170px;
  width: 100% !important;
  margin: auto;
}

.carousel-indicators [data-bs-target] {
  height: 15px !important;
  width: 15px !important;
  border-radius: 50% !important;
  background-color: whitesmoke !important;
  background-clip: unset !important;
  border-top: 0px solid transparent !important;
  border-bottom: 0px solid transparent !important;
  border: 1px solid black !important;
}

.carousel-control-prev,
.carousel-control-next {
  display: none !important;
}

#uniqueInput:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-bottom: 2px solid #007bff !important;
}

.custom-button {
  border: 2px solid red;
  background-color: transparent;
  color: #333;
  padding: 8px 20px;

  transition: color 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.custom-button:hover {
  color: red;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

/* .btn {
  z-index: 10;
}
.page {
  z-index: 10;
}
.zindex10 {
  z-index: 10;
}

.zindex5{
  z-index:5;
}

.table > thead {
  vertical-align: bottom;
  border-top: solid 1px #ffe1da;
  border-bottom: solid 1px #ffe1da;
  background: rgb(255, 243, 242);
  background: linear-gradient(
    90deg,
    rgba(255, 225, 218, 0.9),
    rgba(211, 233, 245, 0.9)
  );
}
 */
